import { template as template_f91babad6f2c4d329be04f99c6a3df37 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f91babad6f2c4d329be04f99c6a3df37(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
