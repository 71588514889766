import { template as template_b86bb9e2057045d58b0f34051b8f5d5f } from "@ember/template-compiler";
const WelcomeHeader = template_b86bb9e2057045d58b0f34051b8f5d5f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
