import { template as template_c3e0718a7b7a4d1abd7d4aaa7da60c9f } from "@ember/template-compiler";
const FKText = template_c3e0718a7b7a4d1abd7d4aaa7da60c9f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
