import { template as template_0b5f61e404eb48cba9b09bb6972ef6df } from "@ember/template-compiler";
const FKLabel = template_0b5f61e404eb48cba9b09bb6972ef6df(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
